<template>
  <div class="xrayImg">
    <div class="imgW" v-ripple>
      <img v-if="xrayMap?.rsltUrl && xrayMap?.modThumbNm" 
           :src="getImageSrc(xrayMap.rgRsltUrl, xrayMap.modThumbNm)" 
           @click="showDetail(xrayMap.rgRsltUrl + xrayMap.modNm)" />
    </div>
    <div class="txtW">
      <p class="date">촬영일: {{ xrayMap.pictureDtStr || '' }}</p>
      <p class="txt">
        <span class="grade-label">Risser grade :</span>
        <span class="grade-value">{{ xrayMap.risserGrade }}</span>
      </p>
      <a class="dwnImg" title="이미지 다운로드" 
         :href="getDownloadUrl()" 
         download 
         id="gd_img_down">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clip-path="url(#clip0_989_5058)">
            <path d="M5 20H19V18H5V20ZM19 9H15V3H9V9H5L12 16L19 9Z" fill="#777777" />
          </g>
          <defs>
            <clipPath id="clip0_989_5058">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    xrayMap: {
      default() {
        return {
          rsltUrl: '',
          rgRsltUrl: '',
          modNm: '',
          modThumbNm: '',
          pictureDtStr: '',
          risserGrade: '',
        };
      },
    },
  },
  data() {
    return {
      XRAY_TYPE_SPINE: "spine" // 상수 정의
    };
  },
  methods: {
    getImageSrc(rsltUrl, modThumbNm) {
      return `${rsltUrl}${modThumbNm}`;
    },
    getDownloadUrl() {
      return `/api/file/${this.XRAY_TYPE_SPINE}/rg/${this.xrayMap.modNm}?download=true`;
    },
    showDetail(url) {
      // 새로운 창에서 이미지를 엽니다.
      window.open(`/api/file/${this.XRAY_TYPE_SPINE}/rg/${this.xrayMap.modNm}?download=false`, '_blank');
    }
  }
};
</script>

<style scoped>
.xrayImg {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.imgW{
  width: 100%;
  padding-top: 170px !important;
}

.imgW img{
  width: auto;
  height: 100%;
  max-height: fit-content;
  min-height: fit-content;
}

.txtW {
  height: auto;
  width: 100%;
  margin-top: 10px; /* 텍스트 영역과 이미지 사이에 간격을 줌 */
}

.date {
  margin: 0;
  padding: 0;
  margin-bottom: 10px; /* 촬영일과 Risser grade 사이의 간격 */
}

.txt {
  margin: 0;
  padding: 0;
}

.grade-label {
  font-size: 1.4em; /* 글자 크기 키움 */
  margin-right: 5px; /* Risser grade 와 값 사이 간격 추가 */
}

.grade-value {
  font-size: 1.4em;
  font-weight: bold; /* 글자 진하게 */
  color: #A3A7F5; /* 이미지와 같은 색으로 설정 */
}

</style>
